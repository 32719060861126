import React from 'react'
import { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Center, Section } from '../styled'
import { SectionTitle, Paragraph, DiceImg, Subtitle } from './styled'

type QueryResult = {
  dice: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const query = graphql`
  query {
    dice: file(relativePath: { eq: "dice.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const DontRollTheDice = () => {
  const { dice } = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <Center>
        <DiceImg fluid={dice.childImageSharp.fluid} alt="" />
        <SectionTitle>
          Don&apos;t roll the dice on your fertility.
          <br />
          Test your vasectomy with<span translate="no"> Fellow</span>.
        </SectionTitle>
        <Paragraph>
          While most vasectomies are successful, they are not 100% guaranteed.
          That&apos;s why patients need to use another form of birth control to
          avoid pregnancy until vasectomy test results show zero sperm in a
          sample. In some cases, achieving zero sperm takes weeks or months
          longer than expected, and in rare cases, a second vasectomy is
          required. Testing is the only way to ensure you will not conceive a
          child.
        </Paragraph>
        <Subtitle>Get peace of mind by testing conveniently at home.</Subtitle>
      </Center>
    </Section>
  )
}

export default DontRollTheDice
